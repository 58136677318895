
export default {
  props: {
    sidebarNavItems: {
      type: Object,
      default: () => {},
    },

    open: {
      type: Boolean,
      default: true,
    },

    enableAccordion: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      isOpen: this.open,
    }
  },

  watch: {
    open(value) {
      this.isOpen = value
    },
  },

  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen
      if (this.isOpen) {
        this.$emit('open')
      } else {
        this.$emit('close')
      }
    },

    // Only show the children if the current page is the parent page or one of its children
    shouldShowChildren(navItem) {
      if (navItem.page.url === this.$route.path) {
        return true
      }
      return navItem.children.some(
        (child) => child.page.url === this.$route.path
      )
    },

    filterPublishedNavItems(navItems) {
      return navItems.filter(
        (navItem) =>
          navItem.page.status === 'published' ||
          Object.prototype.hasOwnProperty.call(
            this.$route.query,
            'live-preview'
          )
      )
    },
  },
}
