
import sidebar from '~/mixins/sidebar'

export default {
  mixins: [sidebar],

  props: {
    disableAnimation: {
      type: Boolean,
      default: false,
    },

    page: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      openAccordionIndex: 0,
    }
  },

  computed: {
    entryToGetAdditionalNavigationUrl() {
      // Find the url first in the current page, then in its parent
      let current = this.page
      while (current) {
        const url = current.entry_to_get_additional_navigation?.url
        if (url) {
          return url
        }
        current = current.parent
      }
      return null // or a default URL, if appropriate
    },

    additionalSidebarNavItems() {
      if (!this.entryToGetAdditionalNavigationUrl) {
        return null
      }
      return this.createSidebarNavItems(this.entryToGetAdditionalNavigationUrl)
        ?.sidebarNavItems
    },
  },
}
