import { mapState } from 'vuex'

export default {
  data() {
    return {
      layout: 'level1', // can be 'level1', 'level2', 'level3'
      sidebarNavItems: {
        parentPage: null,
        siblings: [],
      },
      routeSegments: '',
      // needed in card component
      pagesStructure: {
        parentPage: null,
        currentPage: null,
        siblings: [],
        children: [],
      },
    }
  },

  computed: {
    ...mapState('navigations', { main: 'unfilteredMain' }),
  },

  mounted() {
    const { sidebarNavItems, pagesStructure } = this.createSidebarNavItems(
      this.$route.path
    )
    this.sidebarNavItems = sidebarNavItems
    this.pagesStructure = pagesStructure
  },

  methods: {
    // create sidebar nav items based on current route path - with structure is the initial call from the current page
    createSidebarNavItems(currentRoutePath, withStructure = true) {
      const locales = this.$i18n.locales.flatMap((locale) => locale.code)

      // filter out locale prefixes in url
      this.routeSegments = currentRoutePath.split('/').filter((segment) => {
        return !locales.includes(segment)
      })

      const level4 = this.routeSegments.length === 5
      const level5 = this.routeSegments.length === 6

      const level4RoutePath = this.getRoutePathOfLevel(4)
      const level5RoutePath = this.getRoutePathOfLevel(5)
      const sidebarNavItems = {
        parentPage: null,
        siblings: [],
      }
      const pagesStructure = {
        parentPage: null,
        currentPage: null,
        siblings: [],
        children: [],
      }

      this.main?.tree
        .filter((item) => !item.page.is_hidden)
        .forEach((level1) => {
          if (
            level1.page.url === currentRoutePath &&
            !level1.page.is_right &&
            !level1.page.is_cta_button &&
            withStructure
          ) {
            return (this.layout = 'level1')
          }

          level1.children.forEach((level2) => {
            if (level2.page.url === currentRoutePath) {
              sidebarNavItems.parentPage = level2.page
              sidebarNavItems.siblings = level2.children.filter(
                (item) => !item.page.is_hidden
              )

              if (withStructure) {
                pagesStructure.parentPage = level1.page
                pagesStructure.currentPage = level2.page
                pagesStructure.siblings = level1.children
                  .filter(
                    (item) =>
                      item.page.url !== currentRoutePath && !item.page.is_hidden
                  )
                  .map((item) => item.page)
                pagesStructure.children = level2.children?.map(
                  (item) => item.page
                )

                return (this.layout = 'level2')
              }

              return
            }

            // can be Level 3 OR Level 4
            // in Level 4 we render the same nav items
            // like in Level 3
            level2.children.forEach((level3) => {
              if (
                (level4 && level4RoutePath === level3.page.url) ||
                (level5 && level5RoutePath === level3.page.url) ||
                level3.page.url === currentRoutePath
              ) {
                sidebarNavItems.parentPage = level2.page
                sidebarNavItems.siblings = level2.children.filter(
                  (item) => !item.page.is_hidden
                )
                if (withStructure) {
                  pagesStructure.parentPage = level2.page
                  pagesStructure.currentPage = level3.page
                  pagesStructure.siblings = level2.children
                    .filter(
                      (item) =>
                        item.page.url !== currentRoutePath &&
                        !item.page.is_hidden
                    )
                    .map((item) => item.page)
                  pagesStructure.children = level3.children?.map(
                    (item) => item.page
                  )
                  this.layout = 'level3'
                }

                if (withStructure) {
                  level3.children.forEach((level4) => {
                    if (level4.page.url === currentRoutePath) {
                      pagesStructure.parentPage = level3.page
                      pagesStructure.currentPage = level4.page
                      pagesStructure.siblings = level3.children
                        .filter(
                          (item) =>
                            item.page.url !== currentRoutePath &&
                            !item.page.is_hidden
                        )
                        .map((item) => item.page)
                      pagesStructure.children = level4.children?.map(
                        (item) => item.page
                      )
                      return (this.layout = 'level4')
                    }
                  })
                }
              }
            })
          })
        })

      return {
        sidebarNavItems,
        pagesStructure,
      }
    },

    getRoutePathOfLevel(level) {
      const part = level - 3
      let path = this.routeSegments
        .slice(0, this.routeSegments.length - part)
        .join('/')

      // concatenate locale prefix to url path when current locale is not the default
      if (this.$i18n.defaultLocale !== this.$i18n.locale) {
        path = '/' + this.$i18n.locale + path
      }

      return path
    },
  },
}
