import { render, staticRenderFns } from "./Sidebar.vue?vue&type=template&id=7d1fc8a6"
import script from "./Sidebar.vue?vue&type=script&lang=js"
export * from "./Sidebar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SidebarNavigation: require('/vercel/path0/components/general/navigation/SidebarNavigation.vue').default,AnimatedContainer: require('/vercel/path0/components/general/AnimatedContainer.vue').default})
